import { useContext, useState } from 'react';
import { UserContext } from '../../../App';
import ClientInfoSection from './ClientInformation';
import PropertyInfo from '../Profile/PropertyInfo';
import BasicPropertyInfo from '../Profile/BasicPropertyInfo';
import InternalInfo from './InternalInfo';
import SuccessMessage from '../../UI/SuccessMessage';
import ShareProjectInfo from './ShareProjectInfo';
import DocumentsSection from './DocumentsSection';
import SubmitButton from './SubmitButton';
import AdditionalInfo from '../Profile/AdditionalInfo';
import ProjectStyle from '../Profile/ProjectStyle';
import LoadingModal from '../Common/LoadingModal';
import { Formik, Form } from 'formik';
import { createProject } from '../../../services/projectService';
import { useNavigate } from 'react-router-dom';

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'jobReference',
    'projectManager',
    'projectStage',
    // ... other required fields ...
  ];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'This field is required';
    }
  });

  // Email validation for projectManager
  if (values.projectManager && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.projectManager)) {
    errors.projectManager = 'Invalid email address';
  }

  return errors;
};

const CreateProjectForm = ({ fetchProjects, fetchSharedProjects }) => {
  const { userContextData } = useContext(UserContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('projectInfo', JSON.stringify(values));

    // Append files to formData
    ['plansKey', 'specificationsKey', 'permitsKey', 'contractsKey'].forEach((field) => {
      if (values[field]) {
        formData.append(field, values[field]);
      }
    });

    try {
      const newProject = await createProject(formData);
      await fetchProjects();
      setShowSuccessMessage(true);
      setProjectId(newProject._id);
      // navigate to top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });

      navigate(`/dashboard/project/summary/${newProject._id}`);
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        clientName: '',
        clientPhone: '',
        clients: [{ name: '', email: '', phone: '', address: '' }],
        admins: [''],
        contractors: [''],
        clientAddress: '',
        numberOfPhases: 1,
        phaseBudgets: [''],
        phases: [{ name: '', budget: '' }],
        projectStage: 'Creation Pack',
        projectManager: '',
        jobReference: '',
        projectStartDate: new Date().toISOString().split('T')[0],
        projectEndDate: new Date().toISOString().split('T')[0],
        projectDescription: '',
        onlineLink: '',
        shareWithEmails: [
          {
            email: '',
            role: 'admin',
          },
        ],
        plansLink: '',
        specificationsLink: '',
        permitsLink: '',
        contractsLink: '',
        plansKey: '',
        specificationsKey: '',
        permitsKey: '',
        contractsKey: '',
      }}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
      }}
    >
      <Form>
        <div className="space-y-12 max-w-3xl mx-auto">
          <InternalInfo />
          <BasicPropertyInfo />
          <ShareProjectInfo userContextData={userContextData} />
          <ClientInfoSection />

          {!showSuccessMessage && (
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <SubmitButton showSuccessMessage={showSuccessMessage} text={'Create Project'} isLoading={isLoading} />
            </div>
          )}
        </div>
      </Form>
    </Formik>
  );
};

export default CreateProjectForm;
