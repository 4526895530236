import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Info, AlertCircle, Clock, CheckCircle2, FileText, PencilRuler, GitPullRequest } from 'lucide-react';
import NotesTable from '../../Projects/Notes/Table';
import { getNotes } from '../../../services/noteService';

const InfoSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const sections = [
    {
      title: 'Note Types',
      icon: <FileText className="w-5 h-5" />,
      content: [
        { label: 'General Notes', description: 'For project updates, meeting notes, and general information' },
        { label: 'Design Notes', description: 'For design decisions, revisions, and visual documentation' },
        { label: 'Change Requests', description: 'For tracking project modifications and client requests' },
      ],
    },
    {
      title: 'Priority Levels',
      icon: <AlertCircle className="w-5 h-5" />,
      content: [
        { label: 'Urgent', description: 'Immediate attention required, critical for project progress' },
        { label: 'High', description: 'Important tasks that need attention soon' },
        { label: 'Normal', description: 'Standard priority for regular tasks' },
        { label: 'Low', description: 'Can be addressed when time permits' },
      ],
    },
    {
      title: 'Best Practices',
      icon: <CheckCircle2 className="w-5 h-5" />,
      content: [
        { label: 'Be Specific', description: 'Include relevant details and context in your notes' },
        { label: 'Link Documents', description: 'Attach relevant files to provide additional context' },
        { label: 'Update Status', description: 'Keep the note status current as work progresses' },
        { label: 'Add Action Items', description: 'Break down tasks into trackable action items' },
      ],
    },
  ];

  return (
    <div className="mb-6 bg-white rounded-lg shadow-sm border border-gray-100">
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between text-left"
        whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.02)' }}
      >
        <div className="flex items-center gap-2">
          <Info className="w-5 h-5 text-[#2A9D8F]" />
          <span className="font-medium text-gray-900">How to Use Project Notes</span>
        </div>
        <motion.div animate={{ rotate: isExpanded ? 180 : 0 }} transition={{ duration: 0.2 }}>
          <ChevronDown className="w-5 h-5 text-gray-400" />
        </motion.div>
      </motion.button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="px-6 pb-6 grid gap-6 md:grid-cols-3">
              {sections.map((section) => (
                <div key={section.title} className="space-y-3">
                  <div className="flex items-center gap-2 text-[#2A9D8F]">
                    {section.icon}
                    <h3 className="font-medium">{section.title}</h3>
                  </div>
                  <div className="space-y-2">
                    {section.content.map((item) => (
                      <div key={item.label} className="space-y-1">
                        <div className="text-sm font-medium text-gray-700">{item.label}</div>
                        <div className="text-sm text-gray-500">{item.description}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ProjectNotesPage = () => {
  const { projectId } = useParams();
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotes = async () => {
    setIsLoading(true);
    try {
      const fetchedNotes = await getNotes(projectId);
      setNotes(fetchedNotes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [projectId]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">
        <InfoSection />
        {isLoading ? (
          <div className="flex items-center justify-center py-12">
            <div className="text-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#2A9D8F]" />
              <p className="mt-2 text-sm text-gray-500">Loading notes...</p>
            </div>
          </div>
        ) : (
          <NotesTable projectId={projectId} notes={notes} refetch={fetchNotes} />
        )}
      </div>
    </div>
  );
};

export default ProjectNotesPage;
