import React from 'react';
import { Field, ErrorMessage } from 'formik';

const BasicPropertyInfo = () => {
  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Your Property Information</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">Please provide basic property information.</p>

      <div className="mt-10">
        <div className="w-full">
          <label htmlFor="propertyAddress" className="block text-sm font-medium leading-6 text-gray-900">
            Property Address
          </label>
          <div className="mt-2">
            <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#2A9D8F]">
              <Field
                type="text"
                name="propertyAddress"
                id="propertyAddress"
                className="ml-2 block w-full border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="10, The Street, London, SW1A 1AA"
              />
            </div>
          </div>
          <ErrorMessage name="propertyAddress" component="p" className="mt-2 text-sm text-red-600" />
        </div>
      </div>
      <div className="mt-10">
        <div className="w-full">
          <label htmlFor="onlineLink" className="block text-sm font-medium leading-6 text-gray-900">
            Rightmove / Online Link
          </label>
          <div className="mt-2">
            <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#2A9D8F]">
              <Field
                type="text"
                name="onlineLink"
                id="onlineLink"
                className="ml-2 block w-full border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="www.rightmove.co.uk/properties/12345678"
              />
            </div>
          </div>
          <ErrorMessage name="onlineLink" component="p" className="mt-2 text-sm text-red-600" />
        </div>
      </div>

      <div className="mt-10">
        <div className="w-full">
          <label htmlFor="councilAuthorityName" className="block text-sm font-medium leading-6 text-gray-900">
            Council Authority Name
          </label>
          <div className="mt-2">
            <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#2A9D8F]">
              <Field
                type="text"
                name="councilAuthorityName"
                id="councilAuthorityName"
                className="ml-2 block w-full border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Westminster City Council"
              />
            </div>
          </div>
          <ErrorMessage name="councilAuthorityName" component="p" className="mt-2 text-sm text-red-600" />
        </div>
      </div>

      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
            Property Type
          </label>
          <Field
            as="select"
            name="type"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          >
            <option value="">Select Type...</option>
            <option value="Detached">Detached</option>
            <option value="Semi-detached">Semi-detached</option>
            <option value="Terraced">Terraced</option>
            <option value="Flat">Flat</option>
            <option value="Bungalow">Bungalow</option>
            <option value="Commercial">Commercial</option>
          </Field>
          <ErrorMessage name="type" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="bedrooms" className="block text-sm font-medium leading-6 text-gray-900">
            Number of Bedrooms
          </label>
          <Field
            type="number"
            name="bedrooms"
            onWheel={(e) => e.target.blur()}
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="bedrooms" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="age" className="block text-sm font-medium leading-6 text-gray-900">
            Age of Property
          </label>
          <Field
            type="text"
            name="age"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="age" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="style" className="block text-sm font-medium leading-6 text-gray-900">
            Style of Property
          </label>
          <Field
            type="text"
            name="style"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="style" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="condition" className="block text-sm font-medium leading-6 text-gray-900">
            Property Condition
          </label>
          <Field
            as="textarea"
            placeholder="Enter the condition of the property here"
            name="condition"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="condition" component="div" className="text-xs text-red-500 italic" />
        </div>
      </div>
    </div>
  );
};

export default BasicPropertyInfo;
