import { Formik, Form } from 'formik';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../App';
import ClientInfoSection from '../CreateProject/ClientInformation';
import PropertyInfo from '../Profile/PropertyInfo';
import OnlineInfo from '../Profile/BasicPropertyInfo';
import BasicPropertyInfo from '../Profile/BasicPropertyInfo';
import InternalInfo from '../CreateProject/InternalInfo';
import EditSuccessMessage from '../../UI/EditSuccessMessage';
import ShareProjectInfo from '../CreateProject/ShareProjectInfo';
import SubmitButton from '../CreateProject/SubmitButton';
import useGetUserRole from '../../Hooks/useGetUserRole';
import { updateProject } from '../../../services/projectService';

// Edit Specific Imports
import ProjectStyle from '../Profile/ProjectStyle';
import AdditionalInfo from '../Profile/AdditionalInfo';
import BudgetSection from '../Profile/BudgetSection';
import FurtherInformationSection from '../Profile/FurtherInformationSection';

const validate = (values) => {
  const errors = {};
  // Add validation logic if needed
  return errors;
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const EditProjectForm = ({ project, fetchProjects, fetchSharedProjects }) => {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [enableClientUpdates, setEnableClientUpdates] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { role: userRole, loading, error } = useGetUserRole(project._id, userContextData.user_id);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (project) {
      setInitialValues({
        ...project,
        clients: project.clients || [{ name: '', email: '', address: '', phone: '' }],
        phases: project.phases || [{ name: '', budget: '' }],
        shareWithEmails: project.shareWithEmails || [{ email: '', role: 'admin' }],
        projectStartDate: formatDate(project.projectStartDate),
        projectEndDate: formatDate(project.projectEndDate),
      });
    }
  }, [project]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();

    ['plansKey', 'specificationsKey', 'permitsKey', 'contractsKey'].forEach((field) => {
      if (values[field]) {
        formData.append(field, values[field]);
      }
    });

    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('projectInfo', JSON.stringify(values));
    formData.append('projectProfileForm', userRole === 'client' || userRole === 'Client' || userRole === 'client, client' ? true : false);

    try {
      const response = await updateProject(project._id, formData);

      if (response.status === 200) {
        await fetchProjects();
        setShowSuccessMessage(true);
        setUserContextData({
          ...userContextData,
          selected_project_status: 'active',
        });
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error updating project:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateClients = async (clientValues) => {
    console.log('Updating clients with:', clientValues);
    const formData = new FormData();

    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('projectInfo', JSON.stringify({ ...project, clients: clientValues }));
    formData.append('projectProfileForm', 'false');

    try {
      const response = await updateProject(project._id, formData);

      if (response.status === 200) {
        console.log('Clients updated successfully');
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
        // Refresh the project data
        await fetchProjects();
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error updating clients:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Formik initialValues={initialValues} enableReinitialize={true} validate={validate} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="space-y-12 max-w-3xl mx-auto">
            {userRole === 'admin' && (
              <ClientInfoSection
                disableUpdateEmails={false}
                onUpdateClients={handleUpdateClients}
                projectId={project._id}
                enableClientUpdates={enableClientUpdates}
                project={project}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
            {userRole === 'admin' && <ShareProjectInfo userContextData={userContextData} />}
            {userRole === 'admin' && <OnlineInfo />}
            {userRole === 'admin' && <InternalInfo />}
            {userRole === 'admin' && <BudgetSection />}
            {userRole === 'admin' && <FurtherInformationSection />}

            <BasicPropertyInfo />

            <PropertyInfo />
            <ProjectStyle />
            <AdditionalInfo />
            {showSuccessMessage && <EditSuccessMessage text={'Project Updated!'} />}

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <SubmitButton showSuccessMessage={showSuccessMessage} text={'Save Project'} isLoading={isLoading} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditProjectForm;
