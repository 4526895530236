import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import SectionHeading from '../../UI/SectionHeading';
import { useParams } from 'react-router-dom';
import useGetProject from '../../Hooks/useGetProject';
import { Spinner } from 'flowbite-react';
import AdditionalInfo from '../../Projects/Profile/AdditionalInfo';
import ProjectStyle from '../../Projects/Profile/ProjectStyle';
import BasicPropertyInfo from '../../Projects/Profile/BasicPropertyInfo';
import PropertyInfo from '../../Projects/Profile/PropertyInfo';
import BudgetSection from '../../Projects/Profile/BudgetSection';
import FurtherInformationSection from '../../Projects/Profile/FurtherInformationSection';
import { updateProject } from '../../../services/projectService';
import { useContext } from 'react';
import { UserContext } from '../../../App';
import { organizationService } from '../../../services/organizationService';

const WelcomeScreen = ({ onStart, organizationPhone }) => (
  <div className="min-h-[80vh] flex flex-col items-center justify-center text-center px-4 py-12">
    <div className="bg-white rounded-2xl p-8 md:p-16 shadow-sm border border-gray-100 max-w-4xl mx-auto">
      <div className="space-y-12">
        <div className="space-y-6">
          <h1 className="text-4xl font-bold tracking-tight text-[#2A9D8F] sm:text-5xl lg:text-6xl">Welcome to DesignVault</h1>
          <div className="h-1.5 w-32 bg-[#2A9D8F] mx-auto rounded-full" />
        </div>

        <div className="space-y-8">
          <p className="text-2xl font-semibold text-gray-900 max-w-3xl mx-auto leading-relaxed">
            To kick-start your project, we will ask you a series of simple questions to help define the scope and brief for your upcoming project.
          </p>

          <div className="inline-flex items-center gap-3 px-6 py-3 bg-[#F4A261]/10 rounded-full">
            <svg className="w-6 h-6 text-[#F4A261]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className="text-lg font-medium text-[#F4A261]">Estimated time 5 to 10 mins to complete</p>
          </div>

          <div className="bg-gray-50 rounded-xl p-8">
            <p className="text-gray-600 max-w-2xl mx-auto text-lg leading-relaxed">
              At anytime you feel you need assistance or can't answer any of the questions please move on
              {organizationPhone && (
                <>
                  , or give us a call on <span className="text-[#2A9D8F] font-medium">{organizationPhone}</span>
                </>
              )}
              .
            </p>
          </div>
        </div>

        <button
          onClick={onStart}
          className="inline-flex items-center gap-3 rounded-lg bg-[#2A9D8F] px-10 py-5 text-xl font-semibold text-white shadow-sm hover:bg-[#2A9D8F]/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2A9D8F] transition-all duration-200 hover:scale-105"
        >
          Start Project Profile
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
        </button>
      </div>
    </div>
  </div>
);

const FormStep = ({ children, currentStep, totalSteps, onBack, onNext, isLastStep, formikProps }) => {
  const progressPercentage = Math.round((currentStep / totalSteps) * 100);

  const handleNextOrSubmit = async () => {
    // Capture the current values, including phases
    const currentValues = { ...formikProps.values };

    const errors = await formikProps.validateForm();
    const hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      if (isLastStep) {
        formikProps.handleSubmit();
      } else {
        // Explicitly preserve the phases
        const preservedValues = {
          ...currentValues,
          phases: Array.isArray(currentValues.phases) ? [...currentValues.phases] : [],
        };

        // Update Formik values before moving to next step
        await formikProps.setValues(preservedValues, false);
        await formikProps.setTouched({});
        onNext(preservedValues);
      }
    } else {
      formikProps.setTouched(errors);
    }
  };

  return (
    <Form className="space-y-8">
      {children}
      <div className="bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          {/* Mobile Layout */}
          <div className="flex flex-col space-y-4 md:hidden">
            <div className="flex items-center gap-x-3 justify-between">
              <div className="flex items-center gap-x-2">
                <button
                  type="button"
                  onClick={async () => {
                    // Preserve phases when going back
                    const currentValues = { ...formikProps.values };
                    const preservedValues = {
                      ...currentValues,
                      phases: Array.isArray(currentValues.phases) ? [...currentValues.phases] : [],
                    };

                    await formikProps.setValues(preservedValues, false);
                    await formikProps.setTouched({});
                    onBack(preservedValues);
                  }}
                  className="flex items-center gap-x-2 px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-900 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M15 18l-6-6 6-6" />
                  </svg>
                  Back
                </button>
                <span className="text-sm text-gray-500">
                  Step {currentStep} of {totalSteps}
                </span>
              </div>
              <button
                type="button"
                onClick={handleNextOrSubmit}
                className="rounded-md bg-[#2A9D8F] px-4 py-1.5 text-sm font-medium text-white hover:bg-[#2A9D8F]/90"
              >
                {isLastStep ? 'Submit' : 'Next'}
              </button>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="flex-1 bg-gray-200 rounded-full h-2">
                <div className="bg-[#2A9D8F] h-2 rounded-full transition-all duration-300" style={{ width: `${progressPercentage}%` }} />
              </div>
              <span className="text-sm font-medium text-gray-700 min-w-[40px]">{progressPercentage}%</span>
            </div>
          </div>

          {/* Desktop Layout (unchanged) */}
          <div className="hidden md:flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <button
                type="button"
                onClick={async () => {
                  // Preserve phases when going back
                  const currentValues = { ...formikProps.values };
                  const preservedValues = {
                    ...currentValues,
                    phases: Array.isArray(currentValues.phases) ? [...currentValues.phases] : [],
                  };

                  await formikProps.setValues(preservedValues, false);
                  await formikProps.setTouched({});
                  onBack(preservedValues);
                }}
                className="flex items-center gap-x-2 px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15 18l-6-6 6-6" />
                </svg>
                Back
              </button>
              <span className="text-sm text-gray-500">
                Step {currentStep} of {totalSteps}
              </span>
            </div>
            <div className="flex items-center gap-x-5">
              <div className="flex items-center gap-x-3">
                <div className="w-72 bg-gray-200 rounded-full h-2">
                  <div className="bg-[#2A9D8F] h-2 rounded-full transition-all duration-300" style={{ width: `${progressPercentage}%` }} />
                </div>
                <span className="text-sm font-medium text-gray-700">{progressPercentage}%</span>
              </div>
              <button
                type="button"
                onClick={handleNextOrSubmit}
                className="rounded-md bg-[#2A9D8F] px-4 py-2 text-sm font-medium text-white hover:bg-[#2A9D8F]/90"
              >
                {isLastStep ? 'Submit' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const SuccessMessage = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="max-w-2xl mx-auto text-center space-y-8 px-4">
        <div className="flex justify-center">
          <div className="rounded-full bg-green-100 p-4">
            <svg className="w-12 h-12 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        </div>
        <div className="space-y-4">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Project Successfully Saved!</h2>
          <p className="text-lg text-gray-600">
            You will be redirected to the project page in <span className="font-semibold text-[#2A9D8F]">{countdown}</span> seconds...
          </p>
        </div>
      </div>
    </div>
  );
};

const ProjectProfileFormPage = () => {
  const { projectId } = useParams();
  const { project, loading, error } = useGetProject(projectId);
  const [step, setStep] = useState(0);
  const totalSteps = 7;
  const [isLoading, setIsLoading] = useState(false);
  const { userContextData } = useContext(UserContext);
  const [formValues, setFormValues] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [organizationPhone, setOrganizationPhone] = useState(null);

  const initialValues = {
    // Basic Property Info
    propertyAddress: project?.propertyAddress || '',
    onlineLink: project?.onlineLink || '',
    councilAuthorityName: project?.councilAuthorityName || '',
    type: project?.type || '',
    bedrooms: project?.bedrooms || '',
    age: project?.age || '',
    style: project?.style || '',
    condition: project?.condition || '',

    // Property Info (Timeline)
    projectStartDate: project?.projectStartDate || '',
    projectEndDate: project?.projectEndDate || '',
    projectTimelineDescription: project?.projectTimelineDescription || '',

    // Budget Info
    budgetType: project?.budgetType || '',
    budget: project?.budget || '',
    phases: project?.phases || [{ name: '', budgetType: '', customBudget: '' }],
    furtherBudgetInfo: project?.furtherBudgetInfo || '',

    // Project Style
    projectStyle: project?.projectStyle || '',
    styleComments: project?.styleComments || '',

    // Additional Info with complete structure
    additionalInfo: {
      additionalSpace: {
        rearSingleStoreyExtension: project?.additionalInfo?.additionalSpace?.rearSingleStoreyExtension || false,
        rearDoubleStoreyExtension: project?.additionalInfo?.additionalSpace?.rearDoubleStoreyExtension || false,
        sideSingleStoreyExtension: project?.additionalInfo?.additionalSpace?.sideSingleStoreyExtension || false,
        sideDoubleStoreyExtension: project?.additionalInfo?.additionalSpace?.sideDoubleStoreyExtension || false,
        infillExtension: project?.additionalInfo?.additionalSpace?.infillExtension || false,
        loftConversion: project?.additionalInfo?.additionalSpace?.loftConversion || false,
        basement: project?.additionalInfo?.additionalSpace?.basement || false,
        garageConversion: project?.additionalInfo?.additionalSpace?.garageConversion || false,
        porch: project?.additionalInfo?.additionalSpace?.porch || false,
        outbuildingGardenRoom: project?.additionalInfo?.additionalSpace?.outbuildingGardenRoom || false,
        newGarageCarport: project?.additionalInfo?.additionalSpace?.newGarageCarport || false,
      },
      internalLayoutReconfiguration: {
        fullReconfigurationOfExisting: project?.additionalInfo?.internalLayoutReconfiguration?.fullReconfigurationOfExisting || false,
        partial: project?.additionalInfo?.internalLayoutReconfiguration?.partial || false,
        affectedAreasOnly: project?.additionalInfo?.internalLayoutReconfiguration?.affectedAreasOnly || false,
        unknown: project?.additionalInfo?.internalLayoutReconfiguration?.unknown || false,
      },
      services: {
        electric: project?.additionalInfo?.services?.electric || false,
        gas: project?.additionalInfo?.services?.gas || false,
        water: project?.additionalInfo?.services?.water || false,
        greenHomeRenewableEnergy: project?.additionalInfo?.services?.greenHomeRenewableEnergy || false,
        unknown: project?.additionalInfo?.services?.unknown || false,
      },
      decoration: {
        wholeHouseInternal: project?.additionalInfo?.decoration?.wholeHouseInternal || false,
        wholeHouseExternal: project?.additionalInfo?.decoration?.wholeHouseExternal || false,
        affectedAreasOnly: project?.additionalInfo?.decoration?.affectedAreasOnly || false,
        unknown: project?.additionalInfo?.decoration?.unknown || false,
      },
      furtherDetails: project?.additionalInfo?.furtherDetails || '',
    },

    // Further Information
    specialRequirements: project?.specialRequirements || '',
    projectImages: project?.projectImages || [],
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      // Format the additionalInfo object to match the backend schema
      const formattedValues = {
        ...values,
        // Preserve existing client and admin information
        clients: project?.clients || [],
        clientEmails: project?.clientEmails || [],
        admins: project?.admins || [],
        additionalInfo: {
          additionalSpace: {
            rearSingleStoreyExtension: values.additionalInfo?.additionalSpace?.rearSingleStoreyExtension || false,
            rearDoubleStoreyExtension: values.additionalInfo?.additionalSpace?.rearDoubleStoreyExtension || false,
            sideSingleStoreyExtension: values.additionalInfo?.additionalSpace?.sideSingleStoreyExtension || false,
            sideDoubleStoreyExtension: values.additionalInfo?.additionalSpace?.sideDoubleStoreyExtension || false,
            infillExtension: values.additionalInfo?.additionalSpace?.infillExtension || false,
            loftConversion: values.additionalInfo?.additionalSpace?.loftConversion || false,
            basement: values.additionalInfo?.additionalSpace?.basement || false,
            garageConversion: values.additionalInfo?.additionalSpace?.garageConversion || false,
            porch: values.additionalInfo?.additionalSpace?.porch || false,
            outbuildingGardenRoom: values.additionalInfo?.additionalSpace?.outbuildingGardenRoom || false,
            newGarageCarport: values.additionalInfo?.additionalSpace?.newGarageCarport || false,
          },
          internalLayoutReconfiguration: {
            fullReconfigurationOfExisting: values.additionalInfo?.internalLayoutReconfiguration?.fullReconfigurationOfExisting || false,
            partial: values.additionalInfo?.internalLayoutReconfiguration?.partial || false,
            affectedAreasOnly: values.additionalInfo?.internalLayoutReconfiguration?.affectedAreasOnly || false,
            unknown: values.additionalInfo?.internalLayoutReconfiguration?.unknown || false,
          },
          services: {
            electric: values.additionalInfo?.services?.electric || false,
            gas: values.additionalInfo?.services?.gas || false,
            water: values.additionalInfo?.services?.water || false,
            greenHomeRenewableEnergy: values.additionalInfo?.services?.greenHomeRenewableEnergy || false,
            unknown: values.additionalInfo?.services?.unknown || false,
          },
          decoration: {
            wholeHouseInternal: values.additionalInfo?.decoration?.wholeHouseInternal || false,
            wholeHouseExternal: values.additionalInfo?.decoration?.wholeHouseExternal || false,
            affectedAreasOnly: values.additionalInfo?.decoration?.affectedAreasOnly || false,
            unknown: values.additionalInfo?.decoration?.unknown || false,
          },
          furtherDetails: values.additionalInfo?.furtherDetails || '',
        },
      };

      const formData = new FormData();

      // Append project info and set projectProfileForm flag
      formData.append('projectInfo', JSON.stringify(formattedValues));
      formData.append('projectProfileForm', 'true');

      // Add userInfo from context
      formData.append(
        'userInfo',
        JSON.stringify({
          auth0_id: userContextData.user_id,
          email: userContextData.email,
        })
      );

      const response = await updateProject(projectId, formData);

      if (response.status === 200) {
        setShowSuccess(true);

        setTimeout(() => {
          window.location.href = `/dashboard/project/summary/${projectId}`;
        }, 5000);
      } else {
        throw new Error('Failed to update project');
      }
    } catch (error) {
      console.error('Error updating project:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const orgData = await organizationService.getOrganization(project.organisation);

        setOrganizationPhone(orgData.number);
      } catch (error) {
        console.error('Error fetching organization details:', error);
      }
    };

    if (project?.organisation) {
      fetchOrganizationDetails();
    }
  }, [project]);

  const renderStep = (currentStep, formikProps) => {
    const goToStep = (newStep, currentValues) => {
      // Deep clone and preserve the phases
      const preservedValues = {
        ...JSON.parse(JSON.stringify(currentValues)),
        phases: Array.isArray(currentValues.phases) ? JSON.parse(JSON.stringify(currentValues.phases)) : [],
      };

      // Update both states
      formikProps.setValues(preservedValues, false);
      setFormValues(preservedValues);
      setStep(newStep);
    };

    const commonStepProps = {
      currentStep,
      totalSteps: totalSteps - 1,
      formikProps,
      onBack: (values) => goToStep(currentStep - 1, values),
      onNext: (values) => goToStep(currentStep + 1, values),
      isLastStep: currentStep === totalSteps - 1,
    };

    switch (currentStep) {
      case 0:
        return <WelcomeScreen onStart={() => setStep(1)} organizationPhone={organizationPhone} />;
      case 1:
        return (
          <FormStep {...commonStepProps}>
            <BasicPropertyInfo />
          </FormStep>
        );
      case 2:
        return (
          <FormStep {...commonStepProps}>
            <AdditionalInfo />
          </FormStep>
        );
      case 3:
        return (
          <FormStep {...commonStepProps}>
            <BudgetSection />
          </FormStep>
        );
      case 4:
        return (
          <FormStep {...commonStepProps}>
            <PropertyInfo disableUpdateEmails={true} />
          </FormStep>
        );
      case 5:
        return (
          <FormStep {...commonStepProps}>
            <ProjectStyle />
          </FormStep>
        );
      case 6:
        return (
          <FormStep {...commonStepProps}>
            <FurtherInformationSection projectId={projectId} />
          </FormStep>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div>
        <Spinner aria-label="Info spinner example" color="info" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 pb-20">
      {showSuccess && <SuccessMessage />}
      <div className="space-y-8">
        {step > 0 && <SectionHeading title="Project Profile Form" />}
        <div className={step === 0 ? '' : 'bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'}>
          <div className={step === 0 ? '' : 'px-4 py-6 sm:p-8'}>
            <Formik
              initialValues={formValues || initialValues}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              {(formikProps) => <div>{renderStep(step, formikProps)}</div>}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectProfileFormPage;
