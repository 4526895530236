import React, { useEffect } from 'react';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';

const PropertyInfo = ({ disableUpdateEmails }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  // Function to format number with commas
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleBudgetChange = (e, index) => {
    const numValue = parseInt(e.target.value.replace(/,/g, ''), 10);
    setFieldValue(`phases.${index}.budget`, isNaN(numValue) ? '' : numValue);
  };

  const handleWheel = (e) => {
    e.target.blur();
  };

  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Timeline Information</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">Please provide details about the timeline of the project.</p>

      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label htmlFor="projectStartDate" className="block text-sm font-medium leading-6 text-gray-900">
            Project Start Date *
          </label>
          <Field
            type="date"
            name="projectStartDate"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <p className="mt-1 text-xs text-gray-500 italic">Please indicate in this section when you would like to begin building works on site, if known.</p>
          <ErrorMessage name="projectStartDate" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="projectEndDate" className="block text-sm font-medium leading-6 text-gray-900">
            Target Project Finish Date *
          </label>
          <Field
            type="date"
            name="projectEndDate"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <p className="mt-1 text-xs text-gray-500 italic">Please indicate when you preference of project completion, if known.</p>
          <ErrorMessage name="projectEndDate" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="projectDescription" className="block text-sm font-medium leading-6 text-gray-900">
            Additional Timeline Information
          </label>
          <Field
            as="textarea"
            placeholder="Please provide any additional information that you think may be useful for us to know."
            name="projectTimelineDescription"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="projectTimelineDescription" component="div" className="text-xs text-red-500 italic" />
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
