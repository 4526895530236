import React from 'react';
import { Field } from 'formik';

const projectStyles = [
  { id: 'contemporary', title: 'Contemporary' },
  { id: 'classic', title: 'Classic' },
  { id: 'country', title: 'Country' },
  { id: 'traditional', title: 'Traditional' },
  { id: 'midCentury', title: 'Mid Century' },
  { id: 'notSure', title: 'Not Sure' },
];

const ProjectStyle = () => {
  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Your Project Style</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        This section provides a basic understanding of the style and appearance you wish to achieve through your project. e
      </p>

      <div className="mt-8 space-y-8">
        <fieldset className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <legend className="text-sm font-semibold leading-6 text-gray-900 mb-2">Please choose a style</legend>
          <div className="mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
              {projectStyles.map((style) => (
                <div key={style.id} className="relative flex items-center p-2 rounded-md  transition-colors cursor-pointer group">
                  <div className="flex h-6 items-center">
                    <Field
                      type="radio"
                      id={style.id}
                      name="projectStyle"
                      value={style.id}
                      className="h-4 w-4 border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                    />
                  </div>
                  <label
                    htmlFor={style.id}
                    className="ml-3 text-sm font-medium text-gray-900 group-hover:text-[#2A9D8F] cursor-pointer transition-colors flex-1"
                  >
                    {style.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </fieldset>

        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <label htmlFor="styleComments" className="block text-sm font-medium leading-6 text-gray-900">
            Style Comments
          </label>
          <div className="mt-4">
            <Field
              as="textarea"
              id="styleComments"
              name="styleComments"
              rows={3}
              placeholder="Add any additional comments about your preferred style..."
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectStyle;
