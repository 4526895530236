import React, { useState, useCallback, useMemo, useEffect, useRef, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FileText, PencilRuler, GitPullRequest, Link2, ClipboardList, Search, SlidersHorizontal, ArrowUpDown, ChevronDown, X } from 'lucide-react';
import NoteSlideOver from './NoteSlideOver';
import { deleteNote } from '../../../services/noteService';
import AddNoteButton from './AddNoteButton';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';
import { toast } from 'react-hot-toast';
import { noteService } from '../../../services/noteService';

const noteTypeIcons = {
  general: <FileText className="w-4 h-4" />,
  design: <PencilRuler className="w-4 h-4" />,
  change: <GitPullRequest className="w-4 h-4" />,
};

const noteTypeLabels = {
  change: 'Change Request',
  general: 'General Note',
  design: 'Design Note',
};

const urgencyColors = {
  low: 'bg-blue-50 text-blue-600 border-blue-200',
  normal: 'bg-green-50 text-green-600 border-green-200',
  high: 'bg-yellow-50 text-yellow-600 border-yellow-200',
  urgent: 'bg-red-50 text-red-600 border-red-200',
};

const statusColors = {
  open: 'bg-yellow-50 text-yellow-600 border-yellow-200',
  in_progress: 'bg-blue-50 text-blue-600 border-blue-200',
  resolved: 'bg-green-50 text-green-600 border-green-200',
  closed: 'bg-gray-50 text-gray-600 border-gray-200',
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

// Update the DropdownMenu component's button styles
const DropdownMenu = ({ children, isOpen, onClose, triggerRef }) => (
  <AnimatePresence>
    {isOpen && (
      <>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="fixed inset-0 z-[100]" onClick={onClose} />
        <motion.div
          ref={triggerRef}
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.2 }}
          className="fixed w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[101]"
        >
          <div className="py-1">
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                className: `block w-full text-left px-4 py-2 text-sm hover:bg-[#2A9D8F]/10 hover:text-[#2A9D8F] focus:bg-[#2A9D8F]/10 focus:text-[#2A9D8F] focus:outline-none transition-colors`,
              })
            )}
          </div>
        </motion.div>
      </>
    )}
  </AnimatePresence>
);

// Add these filter options
const filterOptions = {
  type: [
    { value: 'all', label: 'All Types' },
    { value: 'change', label: 'Change Request' },
    { value: 'general', label: 'General' },
    { value: 'design', label: 'Design' },
  ],
  urgency: [
    { value: 'all', label: 'All Urgencies' },
    { value: 'low', label: 'Low' },
    { value: 'normal', label: 'Normal' },
    { value: 'high', label: 'High' },
    { value: 'urgent', label: 'Urgent' },
  ],
  status: [
    { value: 'all', label: 'All Statuses' },
    { value: 'open', label: 'Open' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'closed', label: 'Closed' },
  ],
};

const TableHeader = ({ column, sortConfig, onSort }) => {
  const isSorted = sortConfig.key === column.key;

  return (
    <motion.th onClick={() => onSort(column.key)} className="group px-6 py-3.5 text-left first:pl-8 last:pr-8 relative">
      <motion.div whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.06)' }} className="flex items-center gap-2 px-3 py-1.5 rounded-md -mx-3 cursor-pointer">
        <span className={`text-xs font-medium tracking-wide transition-colors ${isSorted ? 'text-[#2A9D8F]' : 'text-gray-600 group-hover:text-[#2A9D8F]'}`}>
          {column.label}
        </span>
        <motion.div
          animate={{
            rotate: isSorted ? (sortConfig.direction === 'asc' ? 0 : 180) : 0,
            opacity: isSorted ? 1 : 0,
          }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex items-center"
        >
          <ArrowUpDown className={`w-3.5 h-3.5 transition-colors ${isSorted ? 'text-[#2A9D8F]' : 'text-gray-400 group-hover:text-[#2A9D8F]'}`} />
        </motion.div>
        {isSorted && (
          <motion.div
            layoutId="activeHeader"
            className="absolute inset-x-0 -bottom-px h-[2px] bg-[#2A9D8F]"
            initial={false}
            transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
          />
        )}
      </motion.div>
    </motion.th>
  );
};

function NotesTable({ projectId, notes: initialNotes }) {
  const [notes, setNotes] = useState(initialNotes || []);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    type: 'all',
    urgency: 'all',
    status: 'all',
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'timestamp',
    direction: 'desc',
  });
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { userContextData } = useContext(UserContext);

  console.log('NOTES', notes);

  const navigate = useNavigate();

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.dropdown-container')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  const handleRowClick = (note) => {
    navigate(`/dashboard/project/notes/${projectId}/${note._id}`);
  };

  const refetchNotes = async () => {
    try {
      const notesResponse = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/notes/project/${projectId}/notes`);
      setNotes(notesResponse.data);
    } catch (error) {
      console.error('Failed to fetch notes:', error);
    }
  };

  const handleDelete = async (e, note) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await deleteNote(note._id);
        await refetchNotes();
      } catch (error) {
        console.error('Error deleting note:', error);
        alert('Failed to delete note');
      }
    }
  };

  const handleSlideOverClose = useCallback(async () => {
    setIsSlideOverOpen(false);
    await refetchNotes();
  }, []);

  const handleNoteUpdate = useCallback((updatedNote) => {
    setNotes((prevNotes) => prevNotes.map((n) => (n._id === updatedNote._id ? updatedNote : n)));
    setSelectedNote(updatedNote);
  }, []);

  const handleAddNote = async () => {
    try {
      // Create a draft note first
      const draftNote = await noteService.createDraftNote(projectId, userContextData.user_id);

      // Navigate to the new note's edit page
      navigate(`/dashboard/project/notes/${projectId}/${draftNote._id}`);
    } catch (error) {
      console.error('Error creating draft note:', error);
      toast.error('Failed to create new note');
    }
  };

  const handleSort = (key) => {
    setSortConfig((current) => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const filteredAndSortedNotes = useMemo(() => {
    // First filter out draft notes
    const nonDraftNotes = notes.filter((note) => !note.isDraft);

    // Then apply existing filters
    return nonDraftNotes
      .filter((note) => {
        const matchesSearch =
          searchQuery === '' ||
          note.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          note.content?.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesType = filters.type === 'all' || note.type === filters.type;
        const matchesUrgency = filters.urgency === 'all' || note.urgency === filters.urgency;
        const matchesStatus = filters.status === 'all' || note.status === filters.status;

        return matchesSearch && matchesType && matchesUrgency && matchesStatus;
      })
      .sort((a, b) => {
        if (sortConfig.key === 'timestamp') {
          const dateA = new Date(a[sortConfig.key]);
          const dateB = new Date(b[sortConfig.key]);
          return sortConfig.direction === 'desc' ? dateB - dateA : dateA - dateB;
        }

        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (sortConfig.direction === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
      });
  }, [notes, searchQuery, filters, sortConfig]);

  const filterRefs = {
    type: useRef(null),
    urgency: useRef(null),
    status: useRef(null),
  };

  const columns = [
    { key: 'title', label: 'Note' },
    { key: 'type', label: 'Type' },
    { key: 'tasks', label: 'Tasks' },
    { key: 'author', label: 'Author' },
    { key: 'urgency', label: 'Urgency' },
    { key: 'status', label: 'Status' },
  ];

  const renderTypeCell = (note) => (
    <td className="px-6 py-4 whitespace-nowrap" onClick={() => handleRowClick(note)}>
      <div className="flex items-center cursor-pointer">
        {noteTypeIcons[note.type || 'general']}
        <span className="ml-2 text-sm text-gray-900">{noteTypeLabels[note.type || 'general']}</span>
      </div>
    </td>
  );

  const renderTasksCell = (note) => {
    const totalActions = note.actionItems?.length || 0;
    const completedActions = note.actionItems?.filter((item) => item.status === 'completed').length || 0;

    return (
      <td className="px-6 py-4 whitespace-nowrap" onClick={() => handleRowClick(note)}>
        {totalActions > 0 ? (
          <div className="flex flex-col space-y-2 cursor-pointer">
            <div className="flex items-center space-x-2">
              <div className="flex-1 h-1.5 bg-gray-100 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${(completedActions / totalActions) * 100}%` }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}
                  className="h-full bg-[#2A9D8F] rounded-full"
                />
              </div>
              <span className="text-xs text-gray-500 whitespace-nowrap">
                {completedActions}/{totalActions}
              </span>
            </div>
            <div className="flex items-center space-x-1">
              <span className="text-xs text-gray-500">
                {completedActions === totalActions ? (
                  <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-[#2A9D8F] font-medium">
                    Complete
                  </motion.span>
                ) : (
                  <span>{totalActions - completedActions} remaining</span>
                )}
              </span>
            </div>
          </div>
        ) : (
          <span className="text-xs text-gray-400">No tasks</span>
        )}
      </td>
    );
  };

  const renderAuthorCell = (note) => (
    <td className="px-6 py-4 whitespace-nowrap" onClick={() => handleRowClick(note)}>
      <div className="flex flex-col cursor-pointer">
        <span className="text-sm text-gray-900">{note.authorId?.email || note?.authorEmail || 'N/A'}</span>
        <span className="text-xs text-gray-500">{formatDate(note.timestamp)}</span>
      </div>
    </td>
  );

  return (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-6 border-b border-gray-100">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h3 className="text-xl font-semibold text-gray-900">Project Notes</h3>
            <p className="mt-1 text-sm text-gray-500">{filteredAndSortedNotes.length} notes total</p>
          </div>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="inline-flex items-center px-4 py-2 bg-[#2A9D8F] text-white text-sm font-medium rounded-md hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
            onClick={handleAddNote}
          >
            <span>New Note</span>
          </motion.button>
        </div>

        {/* <div className="mb-6 px-4 py-3 bg-[#2A9D8F]/5 border border-[#2A9D8F]/10 rounded-lg">
          <h4 className="text-sm font-medium text-[#2A9D8F] mb-1">About Project Notes</h4>
          <p className="text-sm text-gray-600">
            Track important project information, design decisions, and change requests in one place. Use different note types to categorize your entries:
          </p>
          <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-2">
            <div className="flex items-center gap-2">
              <FileText className="w-4 h-4 text-gray-500" />
              <span className="text-sm text-gray-600">General Notes - For project updates and information</span>
            </div>
            <div className="flex items-center gap-2">
              <PencilRuler className="w-4 h-4 text-gray-500" />
              <span className="text-sm text-gray-600">Design Notes - For design decisions and documentation</span>
            </div>
            <div className="flex items-center gap-2">
              <GitPullRequest className="w-4 h-4 text-gray-500" />
              <span className="text-sm text-gray-600">Change Requests - For tracking project modifications</span>
            </div>
          </div>
        </div> */}

        <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
          <div className="relative flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
              <motion.input
                initial={false}
                animate={{
                  boxShadow: searchQuery ? '0 0 0 2px #2A9D8F' : 'none',
                }}
                type="text"
                placeholder="Search notes..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:border-transparent transition-shadow"
              />
              {searchQuery && (
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute right-3 top-1/2 -translate-y-1/2"
                  onClick={() => setSearchQuery('')}
                >
                  <X className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                </motion.button>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-2">
            {Object.entries(filters).map(([filterKey, filterValue]) => (
              <div key={filterKey} className="relative inline-block dropdown-container">
                <motion.button
                  ref={filterRefs[filterKey]}
                  whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.1)' }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setActiveDropdown(activeDropdown === filterKey ? null : filterKey)}
                  className={`px-3 py-2.5 rounded-lg text-sm font-medium flex items-center space-x-2 focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:ring-offset-2 ${
                    filterValue !== 'all'
                      ? 'bg-[#2A9D8F]/10 text-[#2A9D8F] border border-[#2A9D8F]/20'
                      : 'bg-gray-50 text-gray-700 border border-gray-200 hover:border-[#2A9D8F]/20'
                  }`}
                >
                  <span>{filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}</span>
                  <ChevronDown className={`w-4 h-4 transition-transform ${activeDropdown === filterKey ? 'transform rotate-180' : ''}`} />
                </motion.button>

                <DropdownMenu isOpen={activeDropdown === filterKey} onClose={() => setActiveDropdown(null)} triggerRef={filterRefs[filterKey]}>
                  {filterOptions[filterKey].map((option) => (
                    <motion.button
                      key={option.value}
                      whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.1)' }}
                      onClick={() => {
                        setFilters((prev) => ({ ...prev, [filterKey]: option.value }));
                        setActiveDropdown(null);
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${
                        filterValue === option.value ? 'text-[#2A9D8F] bg-[#2A9D8F]/10' : 'text-gray-700 hover:text-[#2A9D8F]'
                      }`}
                    >
                      {option.label}
                    </motion.button>
                  ))}
                </DropdownMenu>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200">
        <div className="overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-white border-b border-gray-100">
                {columns.map((column) => (
                  <TableHeader
                    key={column.key}
                    column={column}
                    sortConfig={sortConfig}
                    onSort={(key) => {
                      setSortConfig((current) => ({
                        key,
                        direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
                      }));
                    }}
                  />
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {filteredAndSortedNotes.map((note) => (
                <tr key={note._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4" onClick={() => handleRowClick(note)}>
                    <div className="cursor-pointer">
                      <div className="text-sm font-medium text-gray-900 flex items-center gap-1">
                        {note.relatedDocument && <FileText className="w-4 h-4 text-gray-400 flex-shrink-0" />}
                        {note.title ? (note.title.length > 30 ? `${note.title.slice(0, 30)}...` : note.title) : 'N/A'}
                      </div>
                      <div className="text-sm text-gray-500">
                        {note.content ? (note.content.length > 50 ? `${note.content.slice(0, 50)}...` : note.content) : 'No content'}
                      </div>
                    </div>
                  </td>
                  {renderTypeCell(note)}
                  {renderTasksCell(note)}
                  {renderAuthorCell(note)}
                  <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => handleRowClick(note)}>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${urgencyColors[note.urgency || 'normal']}`}>
                      {note.urgency ? note.urgency.charAt(0).toUpperCase() + note.urgency.slice(1) : 'Normal'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => handleRowClick(note)}>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusColors[note.status || 'open']}`}>
                      {note.status ? note.status.charAt(0).toUpperCase() + note.status.slice(1).replace('_', ' ') : 'Open'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {filteredAndSortedNotes.length === 0 && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="flex flex-col items-center justify-center py-12 bg-gray-50">
          <div className="text-center">
            <ClipboardList className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No notes found</h3>
            <p className="mt-1 text-sm text-gray-500">{searchQuery ? 'Try adjusting your search or filters' : 'Get started by creating a new note'}</p>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}

export default NotesTable;
