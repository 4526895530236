import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FileText,
  PencilRuler,
  GitPullRequest,
  Clock,
  User,
  AlertCircle,
  Check,
  X,
  ClockIcon,
  ChevronDown,
  Trash2,
  Link2,
  ExternalLink,
  CheckCircle2,
  Circle,
  Timer,
  Plus,
  CheckCircle,
  UserCircle,
  Calendar,
  ActivityIcon,
} from 'lucide-react';
import { ArrowLeft } from 'lucide-react';
import { noteService } from '../../../services/noteService';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../../App';
import api from '../../../utils/api';
import { documentService } from '../../../services/documentService';
import TopNav from '../../../components/Projects/Notes/TopNav';
import MetaData from '../../../components/Projects/Notes/MetaData';
import ActionItems from '../../../components/Projects/Notes/ActionItems';
import DeleteNoteModal from '../../../components/Projects/Notes/DeleteNoteModal';
import StatusHistory from '../../../components/Projects/Notes/StatusHistory';
import DocumentLink from '../../../components/Projects/Notes/DocumentLink';
import NoteContent from '../../../components/Projects/Notes/NoteContent';
import NoteHeader from '../../../components/Projects/Notes/NoteHeader';

const noteTypes = [
  { id: 'general', icon: <FileText className="w-4 h-4" />, label: 'General Note' },
  { id: 'design', icon: <PencilRuler className="w-4 h-4" />, label: 'Design Note' },
  { id: 'change', icon: <GitPullRequest className="w-4 h-4" />, label: 'Change Request' },
];

const urgencyLevels = [
  { id: 'low', color: 'bg-blue-50 text-blue-600 border-blue-200', label: 'Low Priority' },
  { id: 'normal', color: 'bg-green-50 text-green-600 border-green-200', label: 'Normal' },
  { id: 'high', color: 'bg-yellow-50 text-yellow-600 border-yellow-200', label: 'High Priority' },
  { id: 'urgent', color: 'bg-red-50 text-red-600 border-red-200', label: 'Urgent' },
];

const noteStatuses = [
  {
    id: 'open',
    label: 'Open',
    icon: <AlertCircle className="w-4 h-4" />,
    color: 'text-yellow-600 bg-yellow-50 border-yellow-200',
  },
  {
    id: 'in_progress',
    label: 'In Progress',
    icon: <ClockIcon className="w-4 h-4" />,
    color: 'text-blue-600 bg-blue-50 border-blue-200',
  },
  {
    id: 'resolved',
    label: 'Resolved',
    icon: <Check className="w-4 h-4" />,
    color: 'text-green-600 bg-green-50 border-green-200',
  },
  {
    id: 'closed',
    label: 'Closed',
    icon: <X className="w-4 h-4" />,
    color: 'text-gray-600 bg-gray-50 border-gray-200',
  },
];

export default function ProjectNotePage() {
  const { projectId, noteId } = useParams();
  const navigate = useNavigate();
  const { userContextData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  console.log('userContextData', userContextData);
  console.log('Note ID', noteId);

  const defaultNote = useMemo(
    () => ({
      title: '',
      content: '',
      type: 'general',
      urgency: 'normal',
      status: 'open',
      projectId: projectId,
      authorId: {
        _id: userContextData?.user_id,
        email: userContextData?.email,
      },
      authorEmail: userContextData?.email,
      timestamp: new Date().toISOString(),
      actionItems: [],
      statusHistory: [],
      relatedDocument: '',
    }),
    [projectId, userContextData?.user_id, userContextData?.email]
  );

  const [note, setNote] = useState(defaultNote);
  const [isEditing, setIsEditing] = useState(noteId === 'new');
  const [unsavedActionItems, setUnsavedActionItems] = useState([]);
  const [documents, setDocuments] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState({
    type: false,
    status: false,
    urgency: false,
    document: false,
  });
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const fetchNote = async () => {
      if (noteId === 'new') {
        setNote(defaultNote);
        setIsEditing(true);
        setIsLoading(false);
        return;
      }

      try {
        const fetchedNote = await noteService.getNoteById(noteId);
        setNote(fetchedNote);
        // Set isEditing to true if it's a draft note
        setIsEditing(fetchedNote.isDraft);
        setUnsavedActionItems(fetchedNote.actionItems || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching note:', error);
        toast.error('Failed to load note');
        setIsLoading(false);
      }
    };

    fetchNote();
  }, [noteId, defaultNote]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const documentsData = await documentService.getDocuments(projectId);
        setDocuments(documentsData || {});
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load note');
      }
    };

    if (projectId && noteId) {
      fetchData();
    }
  }, [projectId, noteId]);

  const safeNote = note || defaultNote;

  const handleInputChange = (field, value) => {
    setNote((prev) => ({ ...prev, [field]: value }));
  };

  const handleStatusChange = async (newStatus) => {
    const timestamp = new Date().toISOString();
    const statusUpdate = {
      from: note.status,
      to: newStatus,
      timestamp: timestamp,
      updatedBy: userContextData.email || 'Unknown User',
    };

    const updatedNote = {
      ...note,
      status: newStatus,
      statusHistory: [...(note.statusHistory || []), statusUpdate],
    };

    // Optimistically update UI
    setNote(updatedNote);
    setDropdownOpen({ ...dropdownOpen, status: false });

    try {
      await noteService.updateNote(note._id, {
        ...updatedNote,
        projectId,
        authorId: userContextData.user_id,
      });
    } catch (error) {
      // Revert on error
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
      setNote(note);
    }
  };

  const handleUrgencyChange = async (newUrgency) => {
    // Optimistically update UI
    const updatedNote = {
      ...note,
      urgency: newUrgency,
    };
    setNote(updatedNote);
    setDropdownOpen({ ...dropdownOpen, urgency: false });

    try {
      await noteService.updateNote(note._id, {
        ...updatedNote,
        projectId,
        authorId: userContextData.user_id,
      });
    } catch (error) {
      // Revert on error
      console.error('Error updating urgency:', error);
      toast.error('Failed to update urgency');
      setNote(note);
    }
  };

  console.log('NOTE', note);

  const handleSave = async () => {
    try {
      if (isNoteEmpty(note)) {
        await noteService.deleteNote(note._id);
        toast.success('Empty note deleted');
        navigate(`/dashboard/project/notes/${projectId}`);
        return;
      }

      const noteData = {
        ...note,
        isDraft: false,
        projectId,
        authorId: userContextData.user_id,
        actionItems: unsavedActionItems,
      };

      const savedNote = await noteService.updateNote(note._id, noteData);
      setNote(savedNote);
      setIsEditing(false);
      toast.success('Note saved successfully');
    } catch (error) {
      console.error('Error saving note:', error);
      toast.error('Failed to save note');
    }
  };

  const handleDelete = async () => {
    try {
      await noteService.deleteNote(noteId);
      toast.success('Note deleted successfully');
      navigate(`/dashboard/project/notes/${projectId}`);
    } catch (error) {
      console.error('Error deleting note:', error);
      toast.error('Failed to delete note');
    }
  };

  const getCurrentStatus = () => noteStatuses.find((s) => s.id === (note?.status || 'open'));
  const getCurrentType = () => noteTypes.find((t) => t.id === (note?.type || 'general'));
  const getCurrentUrgency = () => urgencyLevels.find((u) => u.id === (note?.urgency || 'normal'));

  const handleDocumentChange = async (documentId) => {
    try {
      const updatedNote = {
        ...note,
        relatedDocument: documentId || null,
      };

      if (noteId === 'new') {
        setNote(updatedNote);
      } else {
        const savedNote = await noteService.updateNote(noteId, {
          ...updatedNote,
          _id: noteId,
          projectId,
          authorId: userContextData.user_id,
        });
        setNote(savedNote);
        toast.success('Document linked successfully');
      }
    } catch (error) {
      console.error('Error linking document:', error);
      toast.error('Failed to link document');
    }
  };

  const handleRemoveDocument = async () => {
    try {
      const updatedNote = {
        ...note,
        relatedDocument: null,
      };

      if (noteId === 'new') {
        setNote(updatedNote);
      } else {
        const savedNote = await noteService.updateNote(noteId, {
          ...updatedNote,
          _id: noteId,
          projectId,
          authorId: userContextData.user_id,
        });
        setNote(savedNote);
        toast.success('Document unlinked successfully');
      }
    } catch (error) {
      console.error('Error unlinking document:', error);
      toast.error('Failed to unlink document');
    }
  };

  const handleOpenDocument = () => {
    if (!note.relatedDocument || !documents) return;

    // Search through all categories and subcategories
    for (const category of Object.values(documents)) {
      for (const subcategory of Object.values(category)) {
        const doc = subcategory.find((d) => d._id === note.relatedDocument);
        if (doc?.url) {
          window.open(doc.url, '_blank');
          return;
        }
      }
    }

    toast.error('Document URL not found');
  };

  const handleAddActionItem = async (description = '') => {
    const newActionItem = {
      description: description || '',
      status: 'not_started',
      assignedTo: userContextData.email,
      createdAt: new Date().toISOString(),
    };

    const updatedActionItems = [...unsavedActionItems, newActionItem];
    setUnsavedActionItems(updatedActionItems);

    if (noteId === 'new') {
      setNote((prev) => ({
        ...prev,
        actionItems: updatedActionItems,
      }));
      return;
    }

    try {
      const savedNote = await noteService.updateNote(note._id, {
        ...note,
        actionItems: updatedActionItems,
        projectId,
        authorId: userContextData.user_id,
      });

      setNote(savedNote);
      toast.success('Action item added');
    } catch (error) {
      console.error('Error saving action item:', error);
      toast.error('Failed to save action item');
      setUnsavedActionItems([...note.actionItems]);
    }
  };

  const handleActionItemStatusChange = async (index, currentStatus) => {
    const newStatus = currentStatus === 'completed' ? 'not_started' : 'completed';
    const updatedActionItems = unsavedActionItems.map((item, i) => (i === index ? { ...item, status: newStatus } : item));

    setUnsavedActionItems(updatedActionItems);

    if (noteId === 'new') {
      setNote((prev) => ({
        ...prev,
        actionItems: updatedActionItems,
      }));
      return;
    }

    try {
      const savedNote = await noteService.updateNote(note._id, {
        ...note,
        actionItems: updatedActionItems,
        projectId,
        authorId: userContextData.user_id,
      });

      setNote(savedNote);
    } catch (error) {
      console.error('Error updating action item status:', error);
      toast.error('Failed to update action item');
      setUnsavedActionItems([...note.actionItems]);
    }
  };

  const handleActionItemDescriptionChange = async (index, newDescription) => {
    const updatedActionItems = unsavedActionItems.map((item, i) => (i === index ? { ...item, description: newDescription } : item));

    setUnsavedActionItems(updatedActionItems);

    if (noteId === 'new') {
      setNote((prev) => ({
        ...prev,
        actionItems: updatedActionItems,
      }));
      return;
    }

    try {
      const savedNote = await noteService.updateNote(note._id, {
        ...note,
        actionItems: updatedActionItems,
        projectId,
        authorId: userContextData.user_id,
      });

      setNote(savedNote);
    } catch (error) {
      console.error('Error updating action item description:', error);
      toast.error('Failed to update action item');
      setUnsavedActionItems([...note.actionItems]);
    }
  };

  const handleRemoveActionItem = async (index) => {
    const updatedActionItems = unsavedActionItems.filter((_, i) => i !== index);
    setUnsavedActionItems(updatedActionItems);

    if (noteId === 'new') {
      setNote((prev) => ({
        ...prev,
        actionItems: updatedActionItems,
      }));
      return;
    }

    try {
      const savedNote = await noteService.updateNote(note._id, {
        ...note,
        actionItems: updatedActionItems,
        projectId,
        authorId: userContextData.user_id,
      });

      setNote(savedNote);
      toast.success('Action item removed');
    } catch (error) {
      console.error('Error removing action item:', error);
      toast.error('Failed to remove action item');
      setUnsavedActionItems([...note.actionItems]);
    }
  };

  const isNoteEmpty = (note) => {
    return (!note.title || note.title.trim() === '') && (!note.content || note.content.trim() === '') && (!note.actionItems || note.actionItems.length === 0);
  };

  useEffect(() => {
    // Cleanup function that runs when component unmounts
    return () => {
      if (note._id && isNoteEmpty(note) && note.isDraft) {
        // Only delete if it's a draft
        noteService.deleteNote(note._id).catch((error) => {
          console.error('Error deleting empty note:', error);
        });
      }
    };
  }, []); // Empty dependency array so it only runs on unmount

  if (isLoading && noteId !== 'new') {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <TopNav
        onBack={() => navigate(`/dashboard/project/notes/${projectId}`)}
        onDelete={() => noteId !== 'new' && setShowDeleteModal(true)}
        isEditing={isEditing}
        onEdit={() => setIsEditing(true)}
        onSave={handleSave}
        isNew={noteId === 'new'}
      />

      <div className="max-w-4xl mx-auto px-8 py-12">
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
          <NoteHeader
            note={safeNote}
            isEditing={isEditing}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            handleInputChange={handleInputChange}
            noteTypes={noteTypes}
            getCurrentType={getCurrentType}
          />

          <NoteContent isEditing={isEditing} content={safeNote.content} onChange={handleInputChange} />

          <MetaData
            note={safeNote}
            isEditing={isEditing}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            handleStatusChange={handleStatusChange}
            handleUrgencyChange={handleUrgencyChange}
            handleInputChange={handleInputChange}
            noteStatuses={noteStatuses}
            urgencyLevels={urgencyLevels}
            getCurrentStatus={getCurrentStatus}
            getCurrentUrgency={getCurrentUrgency}
            documents={documents}
            relatedDocument={safeNote.relatedDocument}
            onDocumentChange={handleDocumentChange}
            onRemoveDocument={handleRemoveDocument}
            onOpenDocument={handleOpenDocument}
          />

          <ActionItems
            isEditing={isEditing}
            actionItems={unsavedActionItems}
            onAddItem={handleAddActionItem}
            onStatusChange={handleActionItemStatusChange}
            onDescriptionChange={handleActionItemDescriptionChange}
            onRemoveItem={handleRemoveActionItem}
          />

          <StatusHistory statusHistory={safeNote.statusHistory} noteStatuses={noteStatuses} />
        </motion.div>
      </div>

      <DeleteNoteModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} onDelete={handleDelete} />
    </div>
  );
}
