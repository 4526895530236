import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { imageService } from '../../../services/imageService';

const FurtherInformationSection = ({ projectId }) => {
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadError, setUploadError] = useState(null);

  const handleImageUpload = async (e, setFieldValue) => {
    const files = Array.from(e.target.files);
    setUploadError(null);

    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append('image', file);

        const newImage = await imageService.uploadImage(projectId, formData);

        if (newImage) {
          setPreviewImages((prev) => [
            ...prev,
            {
              file,
              preview: URL.createObjectURL(file),
              id: newImage._id,
              url: newImage.url,
            },
          ]);
        }
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      setUploadError('Failed to upload one or more images. Please try again.');
    }
  };

  const removeImage = async (index, setFieldValue) => {
    try {
      const imageToRemove = previewImages[index];
      if (imageToRemove.id) {
        await imageService.deleteImage(imageToRemove.id);
      }

      setPreviewImages((prev) => {
        const newPreviews = [...prev];
        URL.revokeObjectURL(newPreviews[index].preview);
        newPreviews.splice(index, 1);
        return newPreviews;
      });
    } catch (error) {
      console.error('Error removing image:', error);
      setUploadError('Failed to remove image. Please try again.');
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Additional Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Please provide any additional details that might help us understand your project better.</p>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="specialRequirements" className="block text-sm font-medium text-gray-700">
            Do you have any special requirements or considerations?
          </label>
          <Field
            as="textarea"
            id="specialRequirements"
            name="specialRequirements"
            rows={4}
            className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            placeholder="e.g., Need to maintain access to garden, Require disabled access, Must preserve specific features..."
          />
          <ErrorMessage name="specialRequirements" component="div" className="mt-2 text-sm text-red-600" />
        </div>

        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700">Upload Images (Optional)</label>
          <p className="text-sm text-gray-500">
            Upload any reference images, current property photos, or inspiration that might help us understand your vision.
          </p>

          {uploadError && <p className="text-sm text-red-600">{uploadError}</p>}

          <Field>
            {({ form: { setFieldValue } }) => (
              <div className="space-y-4">
                <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="images"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-[#2A9D8F] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2A9D8F] focus-within:ring-offset-2 hover:text-[#2A9D8F]/80"
                      >
                        <span>Upload files</span>
                        <input
                          id="images"
                          name="images"
                          type="file"
                          multiple
                          accept="image/*"
                          className="sr-only"
                          onChange={(e) => handleImageUpload(e, setFieldValue)}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB each</p>
                  </div>
                </div>

                {previewImages.length > 0 && (
                  <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                    {previewImages.map((image, index) => (
                      <div key={index} className="relative group">
                        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200">
                          <img src={image.preview} alt={`Preview ${index + 1}`} className="object-cover" />
                        </div>
                        <button
                          type="button"
                          onClick={() => removeImage(index, setFieldValue)}
                          className="absolute top-2 right-2 p-1 rounded-full bg-white/80 text-gray-600 hover:text-red-600 focus:outline-none"
                        >
                          <XMarkIcon className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default FurtherInformationSection;
