import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage, FieldArray } from 'formik';
import { useFormikContext } from 'formik';

const budgetRanges = [
  { value: '50000-75000', label: '£50,000 - £75,000' },
  { value: '75000-100000', label: '£75,000 - £100,000' },
  { value: '100000-125000', label: '£100,000 - £125,000' },
  { value: '125000-150000', label: '£125,000 - £150,000' },
  { value: '150000-175000', label: '£150,000 - £175,000' },
  { value: '175000-200000', label: '£175,000 - £200,000' },
  { value: '200000-250000', label: '£200,000 - £250,000' },
  { value: '250000plus', label: '£250,000 +' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'custom', label: 'Custom Amount' },
];

const CustomBudgetInput = ({ name, placeholder = 'Enter custom amount' }) => (
  <div className="mt-2">
    <Field name={name}>
      {({ field, form }) => (
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">£</span>
          </div>
          <input
            type="number"
            {...field}
            className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            placeholder={placeholder}
          />
        </div>
      )}
    </Field>
    <ErrorMessage name={name} component="div" className="mt-1 text-sm text-red-600" />
  </div>
);

const BudgetSection = () => {
  const { values, setFieldValue } = useFormikContext();
  const [showPhasedBudget, setShowPhasedBudget] = useState(false);

  // Initialize showPhasedBudget based on existing phases
  useEffect(() => {
    if (values.phases && values.phases.length > 0 && values.phases.some((phase) => phase.name || phase.budgetType)) {
      setShowPhasedBudget(true);
    }
  }, []); // Only run on mount

  const handleBudgetTypeChange = (value) => {
    setFieldValue('budgetType', value, false);
    if (value !== 'custom') {
      setFieldValue('customBudget', '', false);
    }
  };

  const handlePhaseBudgetTypeChange = (index, value) => {
    setFieldValue(`phases.${index}.budgetType`, value, false);
    if (value !== 'custom') {
      setFieldValue(`phases.${index}.customBudget`, '', false);
    }
  };

  // Handle phased budget toggle
  const handlePhasedBudgetToggle = () => {
    setShowPhasedBudget((prev) => {
      const newShowPhasedBudget = !prev;

      if (newShowPhasedBudget) {
        // If switching to phased budget:
        // 1. Initialize with one phase if none exist
        if (!values.phases || values.phases.length === 0) {
          setFieldValue('phases', [{ name: '', budgetType: '', customBudget: '' }], false);
        }
        // 2. Clear single budget
        setFieldValue('budgetType', '', false);
        setFieldValue('customBudget', '', false);
      } else {
        // If switching to single budget:
        // 1. Clear phases
        setFieldValue('phases', [], false);
      }

      return newShowPhasedBudget;
    });
  };

  // Add this effect to ensure budget types don't conflict
  useEffect(() => {
    if (values.phases?.length > 0) {
      // If we have phases, ensure single budget is cleared
      if (values.budgetType || values.customBudget) {
        setFieldValue('budgetType', '', false);
        setFieldValue('customBudget', '', false);
      }
    } else if (values.budgetType || values.customBudget) {
      // If we have a single budget, ensure phases are cleared
      if (values.phases?.length > 0) {
        setFieldValue('phases', [], false);
      }
    }
  }, [values.phases, values.budgetType, values.customBudget]);

  const handleAddPhase = () => {
    const newPhase = { name: '', budgetType: '', customBudget: '' };
    setFieldValue('phases', [...(values.phases || []), newPhase], false);
  };

  const handleRemovePhase = (indexToRemove) => {
    const updatedPhases = values.phases.filter((_, index) => index !== indexToRemove);
    setFieldValue('phases', updatedPhases, false);
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
      <div className="space-y-8">
        <div className="space-y-2">
          <h2 className="text-xl font-semibold text-gray-900">Project Budget</h2>
          <p className="text-sm text-gray-600">
            Please provide an estimate of your budget. Your project budget will cover construction, refurbishment, and decoration costs. It is exclusive of VAT
            and does not include design or professional services.
          </p>
        </div>

        {!showPhasedBudget && (
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {budgetRanges.map((range) => (
                <div key={range.value} className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      type="radio"
                      id={`budget-${range.value}`}
                      name="budgetType"
                      value={range.value}
                      checked={values.budgetType === range.value}
                      onChange={() => handleBudgetTypeChange(range.value)}
                      className="h-4 w-4 border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                    />
                  </div>
                  <label
                    htmlFor={`budget-${range.value}`}
                    className="ml-3 text-sm font-medium text-gray-900 hover:text-[#2A9D8F] cursor-pointer transition-colors"
                  >
                    {range.label}
                  </label>
                </div>
              ))}
            </div>

            {values.budgetType === 'custom' && <CustomBudgetInput name="customBudget" placeholder="Enter custom budget amount" />}
          </div>
        )}

        {showPhasedBudget && (
          <FieldArray name="phases">
            {({ push, remove }) => (
              <div className="space-y-4">
                {values.phases?.map((phase, index) => (
                  <div key={index} className="border border-gray-200 rounded-lg p-4 space-y-4">
                    <div className="flex justify-between items-center">
                      <h3 className="text-lg font-medium text-gray-900">Phase {index + 1}</h3>
                      {index > 0 && (
                        <button type="button" onClick={() => remove(index)} className="text-red-600 hover:text-red-800">
                          Remove Phase
                        </button>
                      )}
                    </div>

                    <div className="space-y-4">
                      <div>
                        <label htmlFor={`phases.${index}.name`} className="block text-sm font-medium text-gray-700">
                          Phase Name
                        </label>
                        <Field
                          name={`phases.${index}.name`}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
                          placeholder="e.g., Ground Floor Extension"
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {budgetRanges.map((range) => (
                          <div key={`${index}-${range.value}`} className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                type="radio"
                                id={`phases.${index}.budgetType-${range.value}`}
                                name={`phases.${index}.budgetType`}
                                value={range.value}
                                checked={values.phases[index]?.budgetType === range.value}
                                onChange={() => handlePhaseBudgetTypeChange(index, range.value)}
                                className="h-4 w-4 border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                              />
                            </div>
                            <label
                              htmlFor={`phases.${index}.budgetType-${range.value}`}
                              className="ml-3 text-sm font-medium text-gray-900 hover:text-[#2A9D8F] cursor-pointer transition-colors"
                            >
                              {range.label}
                            </label>
                          </div>
                        ))}
                      </div>

                      {values.phases[index]?.budgetType === 'custom' && (
                        <CustomBudgetInput name={`phases.${index}.customBudget`} placeholder={`Enter phase ${index + 1} budget`} />
                      )}
                    </div>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={() => push({ name: '', budgetType: '', customBudget: '' })}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-[#2A9D8F] border border-[#2A9D8F] rounded-md hover:bg-[#2A9D8F] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                >
                  Add Another Phase
                </button>
              </div>
            )}
          </FieldArray>
        )}

        <div className="mt-4 flex flex-col sm:flex-row sm:items-center gap-3 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <p className="text-sm text-gray-700 flex-grow">
            If you are planning to carry out the work in phases please click here to bring up another budget table....
          </p>
          <button
            type="button"
            onClick={handlePhasedBudgetToggle}
            className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] rounded-md hover:bg-[#2A9D8F]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition-colors duration-200 whitespace-nowrap min-w-[120px]"
          >
            {showPhasedBudget ? (
              <>
                <span>Hide Phases</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </>
            ) : (
              <>
                <span>Click Here</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </>
            )}
          </button>
        </div>

        <div className="space-y-2">
          <label htmlFor="furtherBudgetInfo" className="block text-sm font-medium text-gray-900">
            Further Information on your budget
          </label>
          <Field
            as="textarea"
            id="furtherBudgetInfo"
            name="furtherBudgetInfo"
            rows={4}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            placeholder="Enter any additional budget information here..."
          />
          <ErrorMessage name="furtherBudgetInfo" component="div" className="text-sm text-red-600" />
        </div>
      </div>
    </div>
  );
};

export default BudgetSection;
