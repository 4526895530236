import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ActivityIcon, ClockIcon, ChevronDown, Link2, ExternalLink } from 'lucide-react';

export default function NoteMetadata({
  note,
  isEditing,
  dropdownOpen,
  setDropdownOpen,
  handleStatusChange,
  handleUrgencyChange,
  handleInputChange,
  noteStatuses,
  urgencyLevels,
  getCurrentStatus,
  getCurrentUrgency,
  documents = {},
  relatedDocument,
  onDocumentChange,
  onRemoveDocument,
  onOpenDocument,
}) {
  const handleStatusSelect = (statusId) => {
    if (note._id === undefined || isEditing) {
      handleInputChange('status', statusId);
      setDropdownOpen({ ...dropdownOpen, status: false });
    } else {
      handleStatusChange(statusId);
    }
  };

  const handleUrgencySelect = (urgencyId) => {
    if (note._id === undefined || isEditing) {
      handleInputChange('urgency', urgencyId);
      setDropdownOpen({ ...dropdownOpen, urgency: false });
    } else {
      handleUrgencyChange(urgencyId);
    }
  };

  const handleDocumentSelect = (value) => {
    onDocumentChange(value);
    setDropdownOpen({ ...dropdownOpen, document: false });
  };

  const getDocumentName = () => {
    if (!documents || !relatedDocument) return 'Document';

    const categories = Object.keys(documents);
    if (!categories.length) return 'Document';

    const firstCategory = documents[categories[0]];
    if (!firstCategory || !firstCategory['']) return 'Document';

    const doc = firstCategory[''].find((doc) => doc._id === relatedDocument);
    return doc?.name || 'Document';
  };

  const handleOpenDocument = () => {
    window.open(relatedDocument.signedUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="mt-10 pt-6 border-t border-gray-100">
      <div className="flex items-center gap-6 flex-nowrap">
        {/* Status Dropdown */}
        <div className="flex items-center gap-3 text-sm text-gray-500">
          <ActivityIcon className="w-4 h-4 text-gray-400" /> Status:
          <div className="relative inline-block">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setDropdownOpen({ ...dropdownOpen, status: !dropdownOpen.status })}
              className={`inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium
                       shadow-sm hover:shadow transition-all duration-200 whitespace-nowrap
                       ${getCurrentStatus()?.color}`}
            >
              {getCurrentStatus()?.icon}
              <span className="ml-1.5">{getCurrentStatus()?.label}</span>
              <ChevronDown
                className={`w-3 h-3 ml-1.5 transition-transform duration-200 
                         ${dropdownOpen.status ? 'rotate-180' : ''}`}
              />
            </motion.button>

            <AnimatePresence>
              {dropdownOpen.status && (
                <motion.div
                  initial={{ opacity: 0, y: -4 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -4 }}
                  className="absolute z-10 mt-2 w-40 bg-white border border-gray-100 
                           rounded-lg shadow-lg overflow-hidden"
                >
                  {noteStatuses.map((status) => (
                    <motion.button
                      key={status.id}
                      whileHover={{ backgroundColor: 'rgba(0,0,0,0.02)' }}
                      onClick={() => handleStatusSelect(status.id)}
                      className={`w-full px-3 py-2 text-left flex items-center space-x-2
                             transition-colors duration-150 text-xs
                             ${note.status === status.id ? status.color : ''}`}
                    >
                      {status.icon}
                      <span className="font-medium">{status.label}</span>
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        {/* Urgency Dropdown */}
        <div className="flex items-center gap-3 text-sm text-gray-500">
          <ClockIcon className="w-4 h-4 text-gray-400" /> Urgency:
          <div className="relative inline-block">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setDropdownOpen({ ...dropdownOpen, urgency: !dropdownOpen.urgency })}
              className={`inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium
                       border transition-all duration-200 shadow-sm hover:shadow whitespace-nowrap
                       ${getCurrentUrgency()?.color}`}
            >
              {getCurrentUrgency()?.label}
              <ChevronDown
                className={`w-3 h-3 ml-1.5 transition-transform duration-200 
                         ${dropdownOpen.urgency ? 'rotate-180' : ''}`}
              />
            </motion.button>

            <AnimatePresence>
              {dropdownOpen.urgency && (
                <motion.div
                  initial={{ opacity: 0, y: -4 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -4 }}
                  className="absolute z-10 mt-2 w-40 bg-white border border-gray-100 
                           rounded-lg shadow-lg overflow-hidden"
                >
                  {urgencyLevels.map((level) => (
                    <motion.button
                      key={level.id}
                      whileHover={{ backgroundColor: 'rgba(0,0,0,0.02)' }}
                      onClick={() => {
                        handleUrgencySelect(level.id);
                      }}
                      className={`w-full px-3 py-2 text-left flex items-center space-x-2
                               transition-colors duration-150 text-xs
                               ${note.urgency === level.id ? level.color : ''}`}
                    >
                      <span className="font-medium">{level.label}</span>
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        {/* Document Link Dropdown */}
        <div className="flex items-center gap-3 text-sm text-gray-500">
          <Link2 className="w-4 h-4 text-gray-400" /> Document:
          <div className="relative inline-block">
            {relatedDocument ? (
              <div className="flex items-center gap-2">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleOpenDocument}
                  className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium
                           text-[#2A9D8F] hover:text-[#238276] shadow-sm hover:shadow 
                           transition-all duration-200"
                >
                  {getDocumentName()}
                  <ExternalLink className="w-3 h-3 ml-1.5" />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    onRemoveDocument();
                    setDropdownOpen({ ...dropdownOpen, document: false });
                  }}
                  className="text-xs text-red-500 hover:text-red-600 px-2 py-1 rounded-full
                           hover:bg-red-50 transition-colors duration-200"
                >
                  Remove
                </motion.button>
              </div>
            ) : (
              <div className="relative inline-block">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setDropdownOpen({ ...dropdownOpen, document: !dropdownOpen.document })}
                  className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium
                           text-[#2A9D8F] hover:text-[#238276] border border-[#2A9D8F] 
                           shadow-sm hover:shadow transition-all duration-200"
                >
                  Link a document
                  <ChevronDown
                    className={`w-3 h-3 ml-1.5 transition-transform duration-200 
                             ${dropdownOpen.document ? 'rotate-180' : ''}`}
                  />
                </motion.button>

                <AnimatePresence>
                  {dropdownOpen.document && (
                    <motion.div
                      initial={{ opacity: 0, y: -4 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -4 }}
                      className="absolute z-10 mt-2 w-56 bg-white border border-gray-100 
                               rounded-lg shadow-lg overflow-hidden"
                    >
                      {documents &&
                        Object.entries(documents).map(
                          ([category, subcategories]) =>
                            subcategories && (
                              <div key={category}>
                                <div className="px-3 py-2 text-xs font-medium text-gray-500 bg-gray-50">{category}</div>
                                {Object.entries(subcategories).map(
                                  ([_, docs]) =>
                                    docs &&
                                    docs.map((doc) => (
                                      <motion.button
                                        key={doc._id}
                                        whileHover={{ backgroundColor: 'rgba(0,0,0,0.02)' }}
                                        onClick={() => handleDocumentSelect(doc._id)}
                                        className="w-full px-3 py-2 text-left text-xs font-medium
                                           text-gray-700 hover:text-[#2A9D8F] transition-colors duration-150"
                                      >
                                        {doc.name} {doc.version ? `(${doc.version})` : ''}
                                      </motion.button>
                                    ))
                                )}
                              </div>
                            )
                        )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
