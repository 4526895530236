import React from 'react';
import { motion } from 'framer-motion';
import { Clock, User } from 'lucide-react';

export default function StatusHistory({ statusHistory, noteStatuses }) {
  if (!statusHistory?.length) return null;

  return (
    <div className="mt-12 pt-8 border-t border-gray-100">
      <div className="flex items-center mb-8">
        <Clock className="w-5 h-5 text-gray-400 mr-2" />
        <h2 className="text-lg font-medium text-gray-900">Status History</h2>
      </div>
      <div className="space-y-6">
        {statusHistory.map((update, index) => {
          const fromStatus = noteStatuses.find((s) => s.id === update.from);
          const toStatus = noteStatuses.find((s) => s.id === update.to);
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="relative pl-10"
            >
              {index !== statusHistory.length - 1 && <div className="absolute left-[13px] top-8 bottom-0 w-[2px] bg-gray-200" />}
              <div className="absolute left-0 top-2 w-[28px] h-[28px] rounded-full border-2 border-white bg-gray-100 shadow-md flex items-center justify-center">
                {toStatus?.icon}
              </div>
              <div className="bg-gray-50 rounded-lg p-4 shadow-sm border border-gray-100">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center space-x-3">
                    <span className={`inline-flex items-center px-2.5 py-1 rounded-full text-sm font-medium ${fromStatus?.color}`}>{fromStatus?.label}</span>
                    <svg className="w-5 h-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                    <span className={`inline-flex items-center px-2.5 py-1 rounded-full text-sm font-medium ${toStatus?.color}`}>{toStatus?.label}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center space-x-2">
                    <User className="w-4 h-4" />
                    <span>{update.updatedBy}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Clock className="w-4 h-4" />
                    <time dateTime={update.timestamp}>
                      {new Date(update.timestamp).toLocaleString(undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </time>
                  </div>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
