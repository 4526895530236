// AddNoteButton.jsx
import React, { useContext } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { noteService } from '../../../services/noteService';
import { UserContext } from '../../../App';
import { toast } from 'react-hot-toast';

const AddNoteButton = ({ projectId }) => {
  const navigate = useNavigate();
  const { userContextData } = useContext(UserContext);

  const handleClick = async () => {
    try {
      // Create a draft note first
      const draftNote = await noteService.createDraftNote(projectId, userContextData.user_id);

      // Navigate to the new note's edit page
      navigate(`/dashboard/project/notes/${projectId}/${draftNote._id}`);
    } catch (error) {
      console.error('Error creating draft note:', error);
      toast.error('Failed to create new note');
    }
  };

  return (
    <button
      onClick={handleClick}
      className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
    >
      <PlusIcon className="h-5 w-5 mr-2" />
      Add Note
    </button>
  );
};

export default AddNoteButton;
