import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { List, ListOrdered, Type } from 'lucide-react';

export default function NoteContent({ isEditing, content, onChange }) {
  const [listType, setListType] = useState('none');

  const getNumberedListIndex = (lines, currentLineStart) => {
    let count = 0;
    let index = 0;
    while (index < currentLineStart) {
      if (content[index] === '\n') {
        const nextLine = content.slice(index + 1).split('\n')[0];
        if (/^\d+\.\s/.test(nextLine)) {
          count++;
        }
      }
      index++;
    }
    return count + 1;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const currentContent = e.target.value;
      const lines = currentContent.split('\n');
      let currentLineStart = currentContent.lastIndexOf('\n', cursorPosition - 1) + 1;
      let currentLine = currentContent.slice(currentLineStart, cursorPosition).trim();

      // Check if the current line is empty (except for bullet/number)
      const isEmptyLine = currentLine === '•' || currentLine === '• ' || /^\d+\.$/.test(currentLine);

      if (isEmptyLine) {
        // Exit list mode if line is empty
        const newContent = currentContent.slice(0, currentLineStart) + '\n' + currentContent.slice(cursorPosition);
        onChange('content', newContent);
        setListType('none');
        return;
      }

      // Handle list continuation
      const hasBullet = currentLine.startsWith('•');
      const hasNumber = /^\d+\.\s/.test(currentLine);

      let newContent;
      if (hasBullet || listType === 'bullet') {
        newContent = currentContent.slice(0, cursorPosition) + '\n• ' + currentContent.slice(cursorPosition);
      } else if (hasNumber || listType === 'numbered') {
        // Get the correct number for the new line
        const nextNumber = getNumberedListIndex(lines, currentLineStart);
        newContent = currentContent.slice(0, cursorPosition) + `\n${nextNumber}. ` + currentContent.slice(cursorPosition);
      } else {
        newContent = currentContent.slice(0, cursorPosition) + '\n' + currentContent.slice(cursorPosition);
      }

      onChange('content', newContent);

      // Set cursor position after the new bullet point or number
      setTimeout(() => {
        if (listType === 'numbered' || hasNumber) {
          const newLineStart = cursorPosition + 1;
          const numberEnd = newContent.indexOf('. ', newLineStart) + 2;
          e.target.selectionStart = e.target.selectionEnd = numberEnd;
        } else {
          const offset = listType === 'bullet' ? 3 : 1;
          e.target.selectionStart = e.target.selectionEnd = cursorPosition + offset;
        }
      }, 0);
    } else if (e.key === 'Tab') {
      // Handle indentation for nested lists
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const currentContent = e.target.value;
      const newContent = currentContent.slice(0, cursorPosition) + '    ' + currentContent.slice(cursorPosition);
      onChange('content', newContent);
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = cursorPosition + 4;
      }, 0);
    } else if (e.key === 'Backspace') {
      const cursorPosition = e.target.selectionStart;
      const currentContent = e.target.value;
      const currentLineStart = currentContent.lastIndexOf('\n', cursorPosition - 1) + 1;
      const currentLine = currentContent.slice(currentLineStart, cursorPosition);

      // Remove bullet/number if backspace at start of list item
      if ((currentLine === '• ' || /^\d+\.\s$/.test(currentLine)) && cursorPosition === currentLineStart + currentLine.length) {
        e.preventDefault();
        const newContent = currentContent.slice(0, currentLineStart) + currentContent.slice(cursorPosition);
        onChange('content', newContent);
        setListType('none');
      }
    }
  };

  const handleListTypeChange = (newType) => {
    if (newType === listType) {
      setListType('none');
    } else {
      setListType(newType);
      // If switching to numbered list, add "1. " at cursor position
      if (newType === 'numbered' && isEditing) {
        const cursorPosition = document.activeElement.selectionStart;
        const currentContent = content;
        const isAtStart = cursorPosition === 0;
        const previousChar = isAtStart ? '' : currentContent[cursorPosition - 1];

        if (isAtStart || previousChar === '\n') {
          const newContent = currentContent.slice(0, cursorPosition) + '1. ' + currentContent.slice(cursorPosition);
          onChange('content', newContent);
        }
      }
    }
  };

  return (
    <div className="mb-8 rounded-lg border border-gray-200 bg-white shadow-sm">
      {isEditing ? (
        <div className="space-y-2">
          <div className="flex items-center space-x-2 p-3 border-b border-gray-200 bg-gray-50 rounded-t-lg">
            <button
              onClick={() => handleListTypeChange('bullet')}
              className={`p-2 rounded transition-colors duration-200 ${
                listType === 'bullet' ? 'bg-blue-50 text-blue-600 border border-blue-200' : 'hover:bg-white hover:shadow-sm'
              }`}
              title="Bullet List (Press Enter for new bullet, Backspace to exit list)"
            >
              <List className="w-4 h-4" />
            </button>
            <button
              onClick={() => handleListTypeChange('numbered')}
              className={`p-2 rounded transition-colors duration-200 ${
                listType === 'numbered' ? 'bg-blue-50 text-blue-600 border border-blue-200' : 'hover:bg-white hover:shadow-sm'
              }`}
              title="Numbered List (Press Enter for next number, Backspace to exit list)"
            >
              <ListOrdered className="w-4 h-4" />
            </button>
            <button
              onClick={() => setListType('none')}
              className={`p-2 rounded transition-colors duration-200 ${
                listType === 'none' ? 'bg-blue-50 text-blue-600 border border-blue-200' : 'hover:bg-white hover:shadow-sm'
              }`}
              title="Regular Text"
            >
              <Type className="w-4 h-4" />
            </button>
            <div className="text-sm text-gray-500 ml-2">{listType !== 'none' && 'Press Backspace on empty line to exit list'}</div>
          </div>
          <motion.textarea
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            value={content}
            onChange={(e) => onChange('content', e.target.value)}
            onKeyDown={handleKeyDown}
            rows={6}
            className="w-full px-4 py-3 bg-transparent border-0 
                     text-gray-900 placeholder-gray-400 leading-relaxed
                     focus:ring-0 resize-y min-h-[150px]
                     transition-all duration-200"
            placeholder={`Start typing${
              listType === 'bullet' ? ' (Press Enter for new bullet)' : listType === 'numbered' ? ' (Press Enter for next number)' : '...'
            }`}
          />
        </div>
      ) : (
        <div className="prose prose-lg max-w-none text-gray-700 leading-relaxed whitespace-pre-wrap p-4">{content}</div>
      )}
    </div>
  );
}
