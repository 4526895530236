import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Trash2 } from 'lucide-react';

export default function NoteTopNav({ onBack, onDelete, isEditing, onEdit, onSave }) {
  return (
    <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-lg border-b border-gray-100">
      <div className="max-w-6xl mx-auto px-8 h-16 flex items-center justify-between">
        <motion.button whileHover={{ x: -4 }} onClick={onBack} className="group flex items-center text-gray-600 hover:text-gray-900 transition-colors">
          <ArrowLeft className="w-5 h-5 mr-2 transition-transform group-hover:-translate-x-1" />
          <span className="text-sm font-medium">Back to Notes</span>
        </motion.button>
        <div className="flex items-center gap-3">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onDelete}
            className="px-4 py-2 text-sm font-medium text-red-600 hover:text-red-700 
                       transition-colors duration-200 flex items-center gap-2 rounded-md
                       hover:bg-red-50"
          >
            <Trash2 className="w-4 h-4" />
            Delete
          </motion.button>
          {!isEditing ? (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onEdit}
              className="px-4 py-2 text-sm font-medium text-[#2A9D8F] hover:text-[#238276] 
                         transition-colors duration-200 rounded-md hover:bg-[#2A9D8F]/10"
            >
              Edit Note
            </motion.button>
          ) : (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onSave}
              className="px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] 
                         hover:bg-[#238276] rounded-md transition-all duration-200
                         shadow-sm hover:shadow"
            >
              Save Note
            </motion.button>
          )}
        </div>
      </div>
    </div>
  );
}
