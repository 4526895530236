import React from 'react';
import { motion } from 'framer-motion';
import { UserCircle, Calendar, ChevronDown } from 'lucide-react';

export default function NoteHeader({ note, isEditing, dropdownOpen, setDropdownOpen, handleInputChange, noteTypes, getCurrentType }) {
  return (
    <div className="mb-8">
      {/* Title */}
      {isEditing ? (
        <motion.input
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          type="text"
          value={note.title}
          onChange={(e) => handleInputChange('title', e.target.value)}
          className="w-full px-0 py-3 mb-6 bg-transparent border-0 border-b border-gray-200
                   text-3xl font-semibold text-gray-900 placeholder-gray-400
                   focus:ring-0 focus:border-[#2A9D8F]
                   transition-all duration-200"
          placeholder="Note title"
        />
      ) : (
        <h1 className="text-3xl font-semibold text-gray-900 mb-6">{note.title}</h1>
      )}

      {/* Metadata */}
      <div className="flex flex-col space-y-4 text-sm text-gray-500">
        {/* Author, Date, and Note Type */}
        <div className="flex items-center space-x-6">
          <div className="flex items-center">
            <UserCircle className="w-4 h-4 mr-2" />
            <span>{note.authorId.email || 'Unknown'}</span>
          </div>
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-2" />
            <span>{new Date(note.timestamp).toLocaleDateString()}</span>
          </div>
          {isEditing ? (
            <div className="relative inline-block">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setDropdownOpen({ ...dropdownOpen, type: !dropdownOpen.type })}
                className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium
                         border border-gray-200 hover:border-gray-300 bg-white
                         shadow-sm hover:shadow transition-all duration-200"
              >
                {getCurrentType()?.icon}
                <span className="ml-2">{getCurrentType()?.label}</span>
                <ChevronDown
                  className={`w-3 h-3 ml-1.5 transition-transform duration-200 
                           ${dropdownOpen.type ? 'rotate-180' : ''}`}
                />
              </motion.button>

              {dropdownOpen.type && (
                <motion.div
                  initial={{ opacity: 0, y: -4 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -4 }}
                  className="absolute z-10 mt-2 w-40 bg-white border border-gray-100 
                           rounded-lg shadow-lg overflow-hidden"
                >
                  {noteTypes.map((type) => (
                    <motion.button
                      key={type.id}
                      whileHover={{ backgroundColor: 'rgba(0,0,0,0.02)' }}
                      onClick={() => {
                        handleInputChange('type', type.id);
                        setDropdownOpen({ ...dropdownOpen, type: false });
                      }}
                      className="w-full px-3 py-2 text-left flex items-center space-x-2
                               transition-colors duration-150 text-xs"
                    >
                      {type.icon}
                      <span className="font-medium">{type.label}</span>
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </div>
          ) : (
            <div className="flex items-center">
              {getCurrentType()?.icon}
              <span className="ml-2">{getCurrentType()?.label}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
